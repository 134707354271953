$mobile: 320px;
$mediumTablet: 767px;
$largeTablet: 1024px;
$desktop: 1399px;
$largeScreen: 1980px;

.ml-10 {
  margin-left: 10em;
}
.mr-6 {
  margin-right: 6em;
}
.dashboard-text-block {
  margin-bottom: 0;
  margin-top: 6px;
}
.dashboard-block {
  //box-shadow: 1px 0px 4px 2px #ccc;
  padding: 12px;
  //border-radius: 16px;
  min-width: 100%;
  @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
    min-width: auto;
  }
}
.pd-1 {
  padding: 1em !important;
}
.grid-mb-1 {
  @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
    margin-bottom: 1em !important;
  }
}
.order-input-paperSty {
  padding: 0px 20px;
  @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
    margin-top: 6rem !important;
  }
}

.order-input-table {
  margin-bottom: 0;
}
.m-0 {
  margin: 0;
}
.order-input-div {
  h4 {
    margin-top: 0;
    margin-bottom: 6px;
  }
}
.filter-grid {
  background: var(--white);
  border-radius: 10px;
  box-shadow: 0 0 2px var(--dark-sand);
}
.page-header {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 8px;
}
.dialog-header {
  font-size: 20px;
  margin-top: -14px;
  margin-bottom: 27px;
}
.order-input-div {
  margin-top: 1em;
  max-height: 410px;
  overflow: hidden auto;
  padding: 1em;
  box-shadow: 0 0 2px var(--dark-sand);
}
.master-data {
  margin-top: 0.3em;
  max-height: 550px;
  overflow: hidden auto;
  padding: 1em;
  box-shadow: 0 0 2px var(--dark-sand);
}
.master-paperSty {
  @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
    margin-top: 6rem !important;
  }
}
.Table-margin {
  margin: 6px !important;
}
.button-submit{
  padding: 7px !important;
}
.content{
  color: var(--error-red--color);
}
// .button-submit{
//   padding: 7px !important;
// }

// .MuiGrid-grid-xs-3-712 {
//   max-width: 19%;
// }
// .MuiDialogContent-root {
//   padding: 83px 53px !important;
// }
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}