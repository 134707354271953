.bulk-upload {
    padding-bottom: 40px;
  }
  //   .MuiSvgIcon-root {
  //     background: transparent !important;
  //   }
  .bulk-upload-paperSty {
    padding: 10px;
    margin-top: 30px;
    background: var(--white);
    border-radius: 10px;
    box-shadow: 0 0 2px var(--dark-sand);
    .pb-1 {
        padding-bottom: 1em;
    }
    .pr-3 {
        padding-right: 3em;
    }
  }
  .bulk-upload-sample-template {
    display: flex;
    justify-content: space-around;
  
    @media screen and (min-width: 365px) and (max-width: 1024px) {
      flex-direction: column;
      justify-content: center;
    }
    // @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
    //   margin-top: 6rem !important;
    // }
  }
  
  .bulk-upload-form {
    .bulk-upload {
      margin-right: 24px;
  
      label {
        transform: translate(12px, 10px) scale(0.75);
      }
    }
  }
  .download-btn {
    justify-content: center;
  }
  .ol-bulkUpload-Error {
    font-size: 14px;
    font-weight: bold;
  }
  .ul-bulkUpload-Error {
    font-size: 14px;
    background: none;
    font-weight: normal;
  }
  // .MuiFormHelperText-root {
  //   color: black !important;
  // }
  .dialog-center{
    text-align: center;
  }
  .btn-download-template {
    .MuiButtonBase-root.Mui-disabled {
      cursor: no-drop;
      pointer-events: auto;
    }
    .MuiButton-root:hover.Mui-disabled {
      background: var(--light-sand--type-1) !important;
    }
  }