$mobile: 320px;
$mediumTablet: 767px;
$largeTablet: 1024px;
$desktop: 1399px;
$largeScreen: 1980px;

.m-0 {
  margin: 0;
}
.ml-10 {
  margin-left: 10em;
}
.mr-6 {
  margin-right: 6em;
}
.pd-1 {
  padding: 1em !important;
}
.pd-2 {
  padding: 2em;
}
.grid-mb-1 {
  @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
    margin-bottom: 1em !important;
  }
}
.order-list-paperSty {
  padding: 0px 20px;
  @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
    margin-top: 6rem !important;
  }
}
.order-list-table {
  margin-bottom: 0;
}
.filter-grid {
  background: var(--white);
  border-radius: 10px;
  box-shadow: 0 0 2px var(--dark-sand);
}
.page-header {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 8px;
}
.order-list-div {
  h4 {
    margin-top: 0;
    margin-bottom: 6px;
  }
}
.order-list-div {
  margin-top: 1em;
  max-height: 420px;
  overflow: hidden auto;
  padding: 1em;
  box-shadow: 0 0 2px var(--dark-sand);
}

//.MuiAutocomplete-root-endAdornment {
//   top: -10px;
// }
